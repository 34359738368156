import { addressShortener, Button, ButtonVariant } from '@tenset/components'
import { useAccount, useDisconnect } from 'wagmi'

import { useConnectWalletModal } from '~/hooks'

const { Primary, Secondary } = ButtonVariant

export interface ConnectWalletButtonProps {
  connectLabel?: string
  variant?: ButtonVariant
  onConnect?: () => void
}

export function ConnectWalletButton({
  connectLabel = 'Connect wallet',
  variant,
  onConnect,
}: ConnectWalletButtonProps) {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { showModal, updateOnConnect } = useConnectWalletModal()

  function handleConnect() {
    updateOnConnect(onConnect)

    showModal()
  }

  const _variant = variant || address ? Secondary : Primary

  if (address) {
    return (
      <Button variant={_variant} onClick={() => disconnect()}>
        Disconnect {addressShortener(address)} wallet
      </Button>
    )
  }

  return (
    <Button variant={_variant} onClick={() => handleConnect()}>
      {connectLabel}
    </Button>
  )
}
